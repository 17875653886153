import React from "react";
import Header from "../header/header";
import Footer from "../footer/footerComponent";
import MyGallery from "../gallery/gallery";

import lissage01 from "../../assets/galerie/lissages_afro/lissage_01.jpg";
import lissage02 from "../../assets/galerie/lissages_afro/lissage_02.jpg";
import lissage03 from "../../assets/galerie/lissages_afro/lissage_03.jpg";
import lissage04 from "../../assets/galerie/lissages_afro/lissage_04.jpg";
import lissage05 from "../../assets/galerie/lissages_afro/lissage_05.jpg";
import lissage06 from "../../assets/galerie/lissages_afro/lissage_06.jpg";
import lissage07 from "../../assets/galerie/lissages_afro/lissage_07.jpg";
import lissage08 from "../../assets/galerie/lissages_afro/lissage_08.jpg";
import lissage09 from "../../assets/galerie/lissages_afro/lissage_09.jpg";
import lissage10 from "../../assets/galerie/lissages_afro/lissage_10.jpg";
import lissage11 from "../../assets/galerie/lissages_afro/lissage_11.jpg";
import lissage12 from "../../assets/galerie/lissages_afro/lissage_12.jpg";
import lissage13 from "../../assets/galerie/lissages_afro/lissage_13.jpg";

const GalleryLissages = () => {
  const imagesLissages = [
      {
        original: lissage01,
        thumbnail: lissage01,
        description: "Lissage 1",
      },
      {
        original: lissage02,
        thumbnail: lissage02,
        description: "Lissage 2",
      },
      {
        original: lissage03,
        thumbnail: lissage03,
        description: "Lissage 3",
      },
      {
        original: lissage04,
        thumbnail: lissage04,
        description: "Lissage 4",
      },
      {
        original: lissage05,
        thumbnail: lissage05,
        description: "Lissage 5",
      },
      {
        original: lissage06,
        thumbnail: lissage06,
        description: "Lissage 6",
      },
      {
        original: lissage07,
        thumbnail: lissage07,
        description: "Lissage 7",
      },
      {
        original: lissage08,
        thumbnail: lissage08,
        description: "Lissage 8",
      },
      {
        original: lissage09,
        thumbnail: lissage09,
        description: "Lissage 9",
      },
      {
        original: lissage10,
        thumbnail: lissage10,
        description: "Lissage 10",
      },
      {
        original: lissage11,
        thumbnail: lissage11,
        description: "Lissage 11",
      },
      {
        original: lissage12,
        thumbnail: lissage12,
        description: "Lissage 12",
      },
      {
        original: lissage13,
        thumbnail: lissage13,
        description: "Lissage 13",
      },
    ];

  const pageTitle = "Coiffartiste. Galerie photo de lissages";

  return (
    document.title = pageTitle,
    <>
      <Header />
      <MyGallery images={imagesLissages} showBullets="true" showIndex="true" />
      <Footer />
    </>
  );
};
export default GalleryLissages;