import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./gallery.css";

const MyGallery = ({ images, showBullets, showIndex }) => {
    return (
      <div className="image-gallery-wrapper">
        <ImageGallery items={images} showBullets={showBullets} showIndex={showIndex} />
      </div>
    );
  };
  export default MyGallery;