import React from "react";
import Header from "../header/header";
import Footer from "../footer/footerComponent";
import MyGallery from "../gallery/gallery";

import tresses01 from "../../assets/galerie/tresses_enfants/tresses_afro_enfant_01.jpg";
import tresses02 from "../../assets/galerie/tresses_enfants/tresses_afro_enfant_02.jpg";
import tresses03 from "../../assets/galerie/tresses_enfants/tresses_afro_enfant_03.jpg";
import tresses04 from "../../assets/galerie/tresses_enfants/tresses_afro_enfant_04.jpg";
import tresses05 from "../../assets/galerie/tresses_enfants/tresses_afro_enfant_05.jpg";
import tresses06 from "../../assets/galerie/tresses_enfants/tresses_afro_enfant_06.jpg";
import tresses07 from "../../assets/galerie/tresses_enfants/tresses_afro_enfant_07.jpg";
import tresses08 from "../../assets/galerie/tresses_enfants/tresses_afro_enfant_08.jpg";
import tresses09 from "../../assets/galerie/tresses_enfants/tresses_afro_enfant_09.jpg";
import tresses10 from "../../assets/galerie/tresses_enfants/tresses_afro_enfant_10.jpg";
import tresses11 from "../../assets/galerie/tresses_enfants/tresses_afro_enfant_11.jpg";
import tresses12 from "../../assets/galerie/tresses_enfants/tresses_afro_enfant_12.jpg";
import tresses13 from "../../assets/galerie/tresses_enfants/tresses_afro_enfant_13.jpg";
import tresses14 from "../../assets/galerie/tresses_enfants/tresses_afro_enfant_14.jpg";
import tresses15 from "../../assets/galerie/tresses_enfants/tresses_afro_enfant_15.jpg";

const GalleryTressesEnfants = () => {
  const imagesTressesEnfants = [
      {
        original: tresses01,
        thumbnail: tresses01,
        description: "Tresses enfants 1",
      },
      {
        original: tresses02,
        thumbnail: tresses02,
        description: "Tresses enfants 2",
      },
      {
        original: tresses03,
        thumbnail: tresses03,
        description: "Tresses enfants 3",
      },
      {
        original: tresses04,
        thumbnail: tresses04,
        description: "Tresses enfants 4",
      },
      {
        original: tresses05,
        thumbnail: tresses05,
        description: "Tresses enfants 5",
      },
      {
        original: tresses06,
        thumbnail: tresses06,
        description: "Tresses enfants 6",
      },
      {
        original: tresses07,
        thumbnail: tresses07,
        description: "Tresses enfants 7",
      },
      {
        original: tresses08,
        thumbnail: tresses08,
        description: "Tresses enfants 8",
      },
      {
        original: tresses09,
        thumbnail: tresses09,
        description: "Tresses enfants 9",
      },
      {
        original: tresses10,
        thumbnail: tresses10,
        description: "Tresses enfants 10",
      },
      {
        original: tresses11,
        thumbnail: tresses11,
        description: "Tresses enfants 11",
      },
      {
        original: tresses12,
        thumbnail: tresses12,
        description: "Tresses enfants 12",
      },
      {
        original: tresses13,
        thumbnail: tresses13,
        description: "Tresses enfants 13",
      },
      {
        original: tresses14,
        thumbnail: tresses14,
        description: "Tresses enfants 14",
      },
      {
        original: tresses15,
        thumbnail: tresses15,
        description: "Tresses enfants 15",
      },
    ];

  const pageTitle = "Coiffartiste. Galerie photo de tresses enfants";

  return (
    document.title = pageTitle,
    <>
      <Header />
      <MyGallery images={imagesTressesEnfants} showBullets="true" showIndex="true" />
      <Footer />
    </>
  );
};
export default GalleryTressesEnfants;