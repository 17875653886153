import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./header.css";
import "../../assets/css/main.css";
import { Link, useNavigate } from "react-router-dom";

export default function Header() {
  const navigate = useNavigate();

  const closeNavbar = () => {
    const navbarToggler = document.querySelector(".navbar-toggler");
    const navbarCollapse = document.querySelector(".navbar-collapse");
    
    if (navbarToggler && navbarCollapse.classList.contains("show")) {
      navbarToggler.click();
    }
  };

  return (
    <nav className="navbar navBg navbar-expand-xl">
      <div className="container">
        <div className="nav-contain" onClick={() => { navigate("/"); closeNavbar(); }}>
          {/* <img src={LOGO} alt="logo" /> */}
          <span className="ralewayFont">Coiff'Artiste</span>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarScroll"
          aria-controls="navbarScroll"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarScroll">
          <ul className="navbar-nav navBarData ms-auto navbar-nav-scroll">
            {/* Your menu items here */}
            <li className="nav-item dropdown">
              <Link onClick={closeNavbar} className="nav-link colorForNavFont" to="/">
                Accueil
              </Link>
            </li>
            <li className="nav-item dropdown">
              <Link onClick={closeNavbar} className="nav-link colorForNavFont" to="/prestations">
                Prestations
              </Link>
            </li>
            <li className="nav-item">
              <Link onClick={closeNavbar} className="nav-link colorForNavFont" to="/formations">
                Formations
              </Link>
            </li>
            <li className="nav-item">
              <Link onClick={closeNavbar} className="nav-link colorForNavFont" to="/salon">
                Le salon
              </Link>
            </li>
            <li className="nav-item dropdown">
              <Link
                className="nav-link colorForNavFont dropdown-toggle"
                to="#pages"
                id="pagesDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Galeries
              </Link>
              <div className="dropdown-menu" aria-labelledby="pagesDropdown">
                <Link onClick={closeNavbar} className="dropdown-item" to="/gallery-curly">
                  Curly
                </Link>
                <Link onClick={closeNavbar} className="dropdown-item" to="/gallery-tissages">
                  Tissages
                </Link>
                <Link onClick={closeNavbar} className="dropdown-item" to="/gallery-tresses-femmes">
                  Tresse femmes
                </Link>
                <Link onClick={closeNavbar} className="dropdown-item" to="/gallery-tresses-enfants">
                  Tresses enfants
                </Link>
                <Link onClick={closeNavbar} className="dropdown-item" to="/gallery-tresses-hommes">
                  Tresses hommes
                </Link>
                <Link onClick={closeNavbar} className="dropdown-item" to="/gallery-chignons">
                  Chignons
                </Link>
                <Link onClick={closeNavbar} className="dropdown-item" to="/gallery-coupes-femmes">
                  Coupes femmes
                </Link>
                <Link onClick={closeNavbar} className="dropdown-item" to="/gallery-lissages">
                  Lissages
                </Link>
              </div>
            </li>
            {/* <li className="nav-item">
              <a onClick={closeNavbar} className="nav-link colorForNavFont" href="/#pricing">
                Tarifs
              </a>
            </li> */}
            <li className="nav-item">
              <Link onClick={closeNavbar} className="nav-link colorForNavFont" to="/contact">
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
