import React from "react";
import Header from "../header/header";
import Footer from "../footer/footerComponent";
import MyGallery from "../gallery/gallery";

import curly01 from "../../assets/galerie/afro_curly/curly_01.jpg";
import curly02 from "../../assets/galerie/afro_curly/curly_02.jpg";
import curly03 from "../../assets/galerie/afro_curly/curly_03.jpg";
import curly04 from "../../assets/galerie/afro_curly/curly_04.jpg";
import curly05 from "../../assets/galerie/afro_curly/curly_05.jpg";
import curly06 from "../../assets/galerie/afro_curly/curly_06.jpg";

const GalleryCurly = () => {
  const imagesCurly = [
      {
        original: curly01,
        thumbnail: curly01,
        description: "Curly 1",
      },
      {
        original: curly02,
        thumbnail: curly02,
        description: "Curly 2",
      },
      {
        original: curly03,
        thumbnail: curly03,
        description: "Curly 3",
      },
      {
        original: curly04,
        thumbnail: curly04,
        description: "Curly 4",
      },
      {
        original: curly05,
        thumbnail: curly05,
        description: "Curly 5",
      },
      {
        original: curly06,
        thumbnail: curly06,
        description: "Curly 6",
      },
    ];

  const pageTitle = "Coiffartiste. Galerie photo de coiffures curly";

  return (
    document.title = pageTitle,
    <>
      <Header />
      <MyGallery images={imagesCurly} showBullets="true" showIndex="true" />
      <Footer />
    </>
  );
};
export default GalleryCurly;