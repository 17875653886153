import React from "react";
import Header from "../header/header";
import Footer from "../footer/footerComponent";
import "./Formations.css";
import DropdownItemTags from "./dropdowns/dropdown";
import ActiveIcon from "../../assets/images/active-dropdown.svg";
import InactiveIcon from "../../assets/images/inactive-dropdown.svg";
import TressesImg from "../../assets/images/nattes02.jpg";
import TissageImg from "../../assets/images/tissages06.jpg";
import LissageImg from "../../assets/images/lissage09.jpg";
import CurlyImg from "../../assets/images/curly.jpg";
import AtelierEnfantsImg from "../../assets/images/tresses_hommes.jpg";
import MaquillageImg from "../../assets/images/tresses_enfants.jpg";
import FormationsComponent from "./formationsComponent/formationsComponent";
import BreadCrumbs from "../breadCrumbs/BreadCrumbs";

const pageTitle = "Coiffartiste. Prestations afro européennes";

const Formations = () => {
  const formation = [
    {
      id: "1",
      image: TressesImg,
      head: "Tresses et nattes",
      para: "En partant des connaissances de base, apprenez comment réaliser des tresses et des nattes sur vos cheveux ou sur ceux d'une autre personne",
    },
    {
      id: "2",
      image: TissageImg,
      head: "Tissage",
      para: "Apprenez à réaliser un tissage pour obtenir un résultat naturel",
    },
    {
      id: "3",
      image: LissageImg,
      head: "Lissage brésilien et tanin",
      para: "Découvrez comment réaliser un lissage brésilien et tanin. Vous apprendrez toutes les étapes pour un résultat professionnel",
    },
    {
      id: "4",
      image: CurlyImg,
      head: "Curly",
      para: "Apprenez à réaliser une permanente sur des cheveux crépus pour obtenir des boucles régulières",
    },
    {
      id: "5",
      image: AtelierEnfantsImg,
      head: "Atelier mère enfant",
      para: "Lors de cet atelier vous apprendrez à nourrir, démêler et coiffer votre enfant sans abimer ses cheveux",
    },
    {
      id: "6",
      image: MaquillageImg,
      head: "Atelier maquillage",
      para: "Lors de cet atelier maquillage pour peau noire, vous découvrirez comment appliquer votre fond de teint, comment vous maquiller les yeux et les lèvres et comment choisir la bonne teinte pour votre maquillage",
    },
  ];

  const dropDowns = [
    {
      id: "1",
      dropdownTitle: "Atelier coiffure soin démêlage coiffure enfant 60€",
      para: [
        'Mercredi 19 juin de 13h30 à 16h',
        'Mercredi 21 août de 13h30 à 16h',
        'Mercredi 21 août de 16h30 à 19h',
        'Mercredi 16 octobre de 13h30 à 16h',
        'Mercredi 16 octobre de 16h30 à 19h',
        'Mercredi 27 novembre de 13h30 à 16h',
        'Mercredi 27 novembre de 16h30 à 19h'
      ],
    },
    {
      id: "2",
      dropdownTitle: "Atelier maquillage 50€",
      para: [
        'Mercredi 19 juin de 16h30 à 17h30',
        'Mercredi 25 septembre de 13h30 à 16h30',
        'Mercredi 30 octobre de 13h30 à 16h30',
        'Mercredi 18 décembre de 13h30 à 16h30'
      ],
    },
    {
      id: "3",
      dropdownTitle: "Atelier tresses et nattes 150€",
      para: [
        'Mercredi 3 juillet de 10h à 18h',
        'Mercredi 2 octobre de 10h à 18h',
        'Mercredi 4 décembre de 10h à 18h'
      ],
    },
    {
      id: "4",
      dropdownTitle: "Formation lissage brésilien ou tanin 300€",
      para: [
        'Mercredi 10 juillet de 14h à 18h',
        'Mercredi 28 août de 14h à 18h',
        'Mercredi 9 octobre de 14h à 18h',
        'Mercredi 13 novembre de 14h à 18h',
        'Mercredi 11 décembre de 14h à 18h'
      ],
    },
    {
      id: "5",
      dropdownTitle: "Atelier tissage 100€",
      para: [
        'Mercredi 11 septembre de 14h à 18h',
        'Mercredi 6 novembre de 14h à 18h'
      ],
    },
    {
      id: "6",
      dropdownTitle: "Formation curly 250€",
      para: [
        'Mercredi 18 septembre de 14h à 18h',
        'Mercredi 20 novembre de 14h à 18h'
      ],
    },
  ];

  return (
    document.title = pageTitle,
    <>
      <Header />
      <div className="formations">
        <div className="formations-breadcrumb">
          <BreadCrumbs />
        </div>
        <div className="section4">
          <div className="container">
            <div className="row pt-5 d-flex justify-content-center">
              <div className="col-lg-8" data-aos="fade-down">
                <h2 className="text-center mt-5">Nos formations</h2>
                <p className="mt-5 text-center">
                Le partage des soins et du coiffage est au cœur même de l'identité de Coiff'Artiste.
                Quel que soit votre niveau vous êtes les bienvenus.
                Nos formations conviendront aussi bien aux débutants qu'aux confirmés (professionnels) pour améliorer sa technique et gagner du temps.{" "}
                </p>
              </div>
            </div>
            <div
              className="row stores d-flex justify-content-center"
              data-aos="fade-up"
            >
              {formation.map((items) => {
                return (
                  <div className="col-lg-4 text-center" key={items.id}>
                    <FormationsComponent
                      // imgSrc={items.image}
                      head={items.head}
                      para={items.para}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="section8">
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-7" data-aos="fade-down">
                <h2 className="text-center">Planning des ateliers et formations</h2>
              </div>
            </div>
            <div className="row pt-5 justify-content-center" >
              {/* <div
                className="col-lg-5 col-md-12section9 d-flex justify-content-center"
                data-aos="fade-up"
              >
                <img src={DoubtsLogo} alt="doubts" />
              </div> */}
              <div
                className="col-lg-7 col-md-12 d-flex flex-column my-auto"
                data-aos="fade-up"
              >
                {dropDowns.map((items) => (
                  <div className="row" key={items.id}>
                    <div className="col">
                      <DropdownItemTags
                        dropdownTitle={items.dropdownTitle}
                        activeIcon={ActiveIcon}
                        inactiveIcon={InactiveIcon}
                        para={items.para}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Formations;
