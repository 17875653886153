import React from "react";
import Header from "../header/header";
import Footer from "../footer/footerComponent";
import MyGallery from "../gallery/gallery";

import tissage01 from "../../assets/galerie/afro_tissages/tissage_01.jpg";
import tissage02 from "../../assets/galerie/afro_tissages/tissage_02.jpg";
import tissage03 from "../../assets/galerie/afro_tissages/tissage_03.jpg";
import tissage04 from "../../assets/galerie/afro_tissages/tissage_04.jpg";
import tissage05 from "../../assets/galerie/afro_tissages/tissage_05.jpg";
import tissage06 from "../../assets/galerie/afro_tissages/tissage_06.jpg";
import tissage07 from "../../assets/galerie/afro_tissages/tissage_07.jpg";
import tissage08 from "../../assets/galerie/afro_tissages/tissage_08.jpg";
import tissage09 from "../../assets/galerie/afro_tissages/tissage_09.jpg";
import tissage10 from "../../assets/galerie/afro_tissages/tissage_10.jpg";
import tissage11 from "../../assets/galerie/afro_tissages/tissage_11.jpg";
import tissage12 from "../../assets/galerie/afro_tissages/tissage_12.jpg";
import tissage13 from "../../assets/galerie/afro_tissages/tissage_13.jpg";
import tissage14 from "../../assets/galerie/afro_tissages/tissage_14.jpg";
import tissage15 from "../../assets/galerie/afro_tissages/tissage_15.jpg";
import tissage16 from "../../assets/galerie/afro_tissages/tissage_16.jpg";
import tissage17 from "../../assets/galerie/afro_tissages/tissage_17.jpg";
import tissage18 from "../../assets/galerie/afro_tissages/tissage_18.jpg";
import tissage19 from "../../assets/galerie/afro_tissages/tissage_19.jpg";
import tissage20 from "../../assets/galerie/afro_tissages/tissage_20.jpg";
import tissage21 from "../../assets/galerie/afro_tissages/tissage_21.jpg";
import tissage22 from "../../assets/galerie/afro_tissages/tissage_22.jpg";
import tissage23 from "../../assets/galerie/afro_tissages/tissage_23.jpg";
import tissage24 from "../../assets/galerie/afro_tissages/tissage_24.jpg";
import tissage25 from "../../assets/galerie/afro_tissages/tissage_25.jpg";
import tissage26 from "../../assets/galerie/afro_tissages/tissage_26.jpg";
import tissage27 from "../../assets/galerie/afro_tissages/tissage_27.jpg";
import tissage28 from "../../assets/galerie/afro_tissages/tissage_28.jpg";
import tissage29 from "../../assets/galerie/afro_tissages/tissage_29.jpg";
import tissage30 from "../../assets/galerie/afro_tissages/tissage_30.jpg";
import tissage31 from "../../assets/galerie/afro_tissages/tissage_31.jpg";
import tissage32 from "../../assets/galerie/afro_tissages/tissage_32.jpg";
import tissage33 from "../../assets/galerie/afro_tissages/tissage_33.jpg";
import tissage34 from "../../assets/galerie/afro_tissages/tissage_34.jpg";
import tissage35 from "../../assets/galerie/afro_tissages/tissage_35.jpg";
import tissage36 from "../../assets/galerie/afro_tissages/tissage_36.jpg";
import tissage37 from "../../assets/galerie/afro_tissages/tissage_37.jpg";
import tissage38 from "../../assets/galerie/afro_tissages/tissage_38.jpg";
import tissage39 from "../../assets/galerie/afro_tissages/tissage_39.jpg";
import tissage40 from "../../assets/galerie/afro_tissages/tissage_40.jpg";
import tissage41 from "../../assets/galerie/afro_tissages/tissage_41.jpg";
import tissage42 from "../../assets/galerie/afro_tissages/tissage_42.jpg";
import tissage43 from "../../assets/galerie/afro_tissages/tissage_43.jpg";
import tissage44 from "../../assets/galerie/afro_tissages/tissage_44.jpg";
import tissage45 from "../../assets/galerie/afro_tissages/tissage_45.jpg";
import tissage46 from "../../assets/galerie/afro_tissages/tissage_46.jpg";
import tissage47 from "../../assets/galerie/afro_tissages/tissage_47.jpg";
import tissage48 from "../../assets/galerie/afro_tissages/tissage_48.jpg";
import tissage49 from "../../assets/galerie/afro_tissages/tissage_49.jpg";
import tissage50 from "../../assets/galerie/afro_tissages/tissage_50.jpg";
import tissage51 from "../../assets/galerie/afro_tissages/tissage_51.jpg";
import tissage52 from "../../assets/galerie/afro_tissages/tissage_52.jpg";
import tissage53 from "../../assets/galerie/afro_tissages/tissage_53.jpg";
import tissage54 from "../../assets/galerie/afro_tissages/tissage_54.jpg";
import tissage55 from "../../assets/galerie/afro_tissages/tissage_55.jpg";
import tissage56 from "../../assets/galerie/afro_tissages/tissage_56.jpg";
import tissage57 from "../../assets/galerie/afro_tissages/tissage_57.jpg";
import tissage58 from "../../assets/galerie/afro_tissages/tissage_58.jpg";
import tissage59 from "../../assets/galerie/afro_tissages/tissage_59.jpg";
import tissage60 from "../../assets/galerie/afro_tissages/tissage_60.jpg";
import tissage61 from "../../assets/galerie/afro_tissages/tissage_61.jpg";
import tissage62 from "../../assets/galerie/afro_tissages/tissage_62.jpg";
import tissage63 from "../../assets/galerie/afro_tissages/tissage_63.jpg";


const GalleryTissages = () => {
  const imagesTissage = [
      {
        original: tissage01,
        thumbnail: tissage01,
        description: "Tissage 1",
      },
      {
        original: tissage02,
        thumbnail: tissage02,
        description: "Tissage 2",
      },
      {
        original: tissage03,
        thumbnail: tissage03,
        description: "Tissage 3",
      },
      {
        original: tissage04,
        thumbnail: tissage04,
        description: "Tissage 4",
      },
      {
        original: tissage05,
        thumbnail: tissage05,
        description: "Tissage 5",
      },
      {
        original: tissage06,
        thumbnail: tissage06,
        description: "Tissage 6",
      },
      {
        original: tissage07,
        thumbnail: tissage07,
        description: "Tissage 7",
      },
      {
        original: tissage08,
        thumbnail: tissage08,
        description: "Tissage 8",
      },
      {
        original: tissage09,
        thumbnail: tissage09,
        description: "Tissage 9",
      },
      {
        original: tissage10,
        thumbnail: tissage10,
        description: "Tissage 10",
      },
      {
        original: tissage11,
        thumbnail: tissage11,
        description: "Tissage 11",
      },
      {
        original: tissage12,
        thumbnail: tissage12,
        description: "Tissage 12",
      },
      {
        original: tissage13,
        thumbnail: tissage13,
        description: "Tissage 13",
      },
      {
        original: tissage14,
        thumbnail: tissage14,
        description: "Tissage 14",
      },
      {
        original: tissage15,
        thumbnail: tissage15,
        description: "Tissage 15",
      },
      {
        original: tissage16,
        thumbnail: tissage16,
        description: "Tissage 16",
      },
      {
        original: tissage17,
        thumbnail: tissage17,
        description: "Tissage 17",
      },
      {
        original: tissage18,
        thumbnail: tissage18,
        description: "Tissage 18",
      },
      {
        original: tissage19,
        thumbnail: tissage19,
        description: "Tissage 19",
      },
      {
        original: tissage20,
        thumbnail: tissage20,
        description: "Tissage 20",
      },
      {
        original: tissage21,
        thumbnail: tissage21,
        description: "Tissage 21",
      },
      {
        original: tissage22,
        thumbnail: tissage22,
        description: "Tissage 22",
      },
      {
        original: tissage23,
        thumbnail: tissage23,
        description: "Tissage 23",
      },
      {
        original: tissage24,
        thumbnail: tissage24,
        description: "Tissage 24",
      },
      {
        original: tissage25,
        thumbnail: tissage25,
        description: "Tissage 25",
      },
      {
        original: tissage26,
        thumbnail: tissage26,
        description: "Tissage 26",
      },
      {
        original: tissage27,
        thumbnail: tissage27,
        description: "Tissage 27",
      },
      {
        original: tissage28,
        thumbnail: tissage28,
        description: "Tissage 28",
      },
      {
        original: tissage29,
        thumbnail: tissage29,
        description: "Tissage 29",
      },
      {
        original: tissage30,
        thumbnail: tissage30,
        description: "Tissage 30",
      },
      {
        original: tissage31,
        thumbnail: tissage31,
        description: "Tissage 31",
      },
      {
        original: tissage32,
        thumbnail: tissage32,
        description: "Tissage 32",
      },
      {
        original: tissage33,
        thumbnail: tissage33,
        description: "Tissage 33",
      },
      {
        original: tissage34,
        thumbnail: tissage34,
        description: "Tissage 34",
      },
      {
        original: tissage35,
        thumbnail: tissage35,
        description: "Tissage 35",
      },
      {
        original: tissage36,
        thumbnail: tissage36,
        description: "Tissage 36",
      },
      {
        original: tissage37,
        thumbnail: tissage37,
        description: "Tissage 37",
      },
      {
        original: tissage38,
        thumbnail: tissage38,
        description: "Tissage 38",
      },
      {
        original: tissage39,
        thumbnail: tissage39,
        description: "Tissage 39",
      },
      {
        original: tissage40,
        thumbnail: tissage40,
        description: "Tissage 40",
      },
      {
        original: tissage41,
        thumbnail: tissage41,
        description: "Tissage 41",
      },
      {
        original: tissage42,
        thumbnail: tissage42,
        description: "Tissage 42",
      },
      {
        original: tissage43,
        thumbnail: tissage43,
        description: "Tissage 43",
      },
      {
        original: tissage44,
        thumbnail: tissage44,
        description: "Tissage 44",
      },
      {
        original: tissage45,
        thumbnail: tissage45,
        description: "Tissage 45",
      },
      {
        original: tissage46,
        thumbnail: tissage46,
        description: "Tissage 46",
      },
      {
        original: tissage47,
        thumbnail: tissage47,
        description: "Tissage 47",
      },
      {
        original: tissage48,
        thumbnail: tissage48,
        description: "Tissage 48",
      },
      {
        original: tissage49,
        thumbnail: tissage49,
        description: "Tissage 49",
      },
      {
        original: tissage50,
        thumbnail: tissage50,
        description: "Tissage 50",
      },
      {
        original: tissage51,
        thumbnail: tissage51,
        description: "Tissage 51",
      },
      {
        original: tissage52,
        thumbnail: tissage52,
        description: "Tissage 52",
      },
      {
        original: tissage53,
        thumbnail: tissage53,
        description: "Tissage 53",
      },
      {
        original: tissage54,
        thumbnail: tissage54,
        description: "Tissage 54",
      },
      {
        original: tissage55,
        thumbnail: tissage55,
        description: "Tissage 55",
      },
      {
        original: tissage56,
        thumbnail: tissage56,
        description: "Tissage 56",
      },
      {
        original: tissage57,
        thumbnail: tissage57,
        description: "Tissage 57",
      },
      {
        original: tissage58,
        thumbnail: tissage58,
        description: "Tissage 58",
      },
      {
        original: tissage59,
        thumbnail: tissage59,
        description: "Tissage 59",
      },
      {
        original: tissage60,
        thumbnail: tissage60,
        description: "Tissage 60",
      },
      {
        original: tissage61,
        thumbnail: tissage61,
        description: "Tissage 61",
      },
      {
        original: tissage62,
        thumbnail: tissage62,
        description: "Tissage 62",
      },
      {
        original: tissage63,
        thumbnail: tissage63,
        description: "Tissage 63",
      },
    ];

  const pageTitle = "Coiffartiste. Galerie photo de tissages";

  return (
    document.title = pageTitle,
    <>
      <Header />
      <MyGallery images={imagesTissage} showBullets="true" showIndex="true" />
      <Footer />
    </>
  );
};
export default GalleryTissages;