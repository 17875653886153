import React, { Suspense, lazy, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Prestations from "../modules/prestations/Prestations";
import Formations from "../modules/formations/Formations";
import PageNotFound from "../modules/pageNotFound/PageNotFound";
import Salon from "../modules/salon/Salon";
import UnderConstruction from "../modules/underConstruction/UnderConstruction";
import Logo from "../assets/images/Logo.png";
import GalleryCurly from "../modules/gallery-curly/gallery-curly";
import GalleryTissages from "../modules/gallery-tissages/gallery-tissages";
import GalleryTressesFemmes from "../modules/gallery-tresses-femmes/gallery-tresses-femmes";
import GalleryChignons from "../modules/gallery-chignons/gallery-chignons";
import GalleryCoupes from "../modules/gallery-coupes-femmes/gallery-coupes-femmes";
import GalleryLissages from "../modules/gallery-lissages/gallery-lissages";
import GalleryTressesEnfants from "../modules/gallery-tresses-enfants/gallery-tresses-enfants";
import GalleryTressesHommes from "../modules/gallery-tresses-hommes/gallery-tresses-hommes";

const Accueil = lazy(() => import("../modules/home/homeModuleContainer/accueil"));
const Contact = lazy(() => import("../modules/contact/contactPage"));

const Router = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const delay = async () => {
      const minimumDuration = 2000; // Minimum duration in milliseconds
      const startTime = Date.now();

      // Perform any asynchronous tasks here (if needed)

      const elapsedTime = Date.now() - startTime;
      const remainingTime = Math.max(0, minimumDuration - elapsedTime);

      await new Promise((resolve) => setTimeout(resolve, remainingTime));
      setIsLoading(false);
    };

    delay();
  }, []);

  return (
    <Suspense
      fallback={
        isLoading && (
          <div
          className=""
            style={{
              height: "100vh",
              width: "100%",
              backgroundColor: "white",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "10%",
            }}
          >
            {" "}
            <img
              src={Logo}
              alt=""
              style={{
                width: "20%",
                display: "flex",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
            <h1 style={{ color: "#E21E79" }} className="text-center mt-3">
              Coiff'Artiste
            </h1>
          </div>
        )
      }
    >
      <Routes>
        <Route path="/" element={<Accueil />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/prestations" element={<Prestations />} />
        <Route path="/formations" element={<Formations />} />
        <Route path="/salon" element={<Salon />} />
        <Route path="/underconstruction" element={<UnderConstruction />} />
        <Route path="/gallery-curly" element={<GalleryCurly />} />
        <Route path="/gallery-tissages" element={<GalleryTissages />} />
        <Route path="/gallery-tresses-femmes" element={<GalleryTressesFemmes />} />
        <Route path="/gallery-chignons" element={<GalleryChignons />} />
        <Route path="/gallery-coupes-femmes" element={<GalleryCoupes />} />
        <Route path="/gallery-lissages" element={<GalleryLissages />} />
        <Route path="/gallery-tresses-enfants" element={<GalleryTressesEnfants />} />
        <Route path="/gallery-tresses-hommes" element={<GalleryTressesHommes />} />

        {/* 404 error page if no routes maches  */}

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Suspense>
  );
};

export default Router;
