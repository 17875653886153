import React from "react";
import './ReadMore.css';


const ReadMore =({ link }) => {
    return(
        <div className="read-more">
            <p className="read-more-para"><a href={link}>Voir la galerie</a></p>
        </div>
    )
}
export default ReadMore;