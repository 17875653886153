import React from "react";
import Header from "../header/header";
import Footer from "../footer/footerComponent";
import "./Prestations.css";
import TressesImg from "../../assets/images/nattes02.jpg";
import TissageImg from "../../assets/images/tissages06.jpg";
import LissageImg from "../../assets/images/lissage09.jpg";
import CurlyImg from "../../assets/images/curly.jpg";
import TressesHommesImg from "../../assets/images/tresses_hommes.jpg";
import TressesEnfantsImg from "../../assets/images/tresses_enfants.jpg";
import WhyChooseUs from "./whyChooseUs/WhyChooseUs";
import BreadCrumbs from "../breadCrumbs/BreadCrumbs";

const pageTitle = "Coiffartiste. Prestations afro européennes";

const Prestations = () => {
  const prestationsData = [
    {
      id: "1",
      subhead: "",
      mainhead: "Tresses",
      image:
        TressesImg,
      para: "Gagnez du temps le matin et facilitez vous la vie. Large choix de tresses, nattes...",
      link: "/gallery-tresses-femmes",
    },
    {
      id: "2",
      subhead: "",
      mainhead: "Tissages",
      image:
        TissageImg,
      para: "Permet d'obtenir de la longueur ou du volume. Au choix : fermé, ouvert, demi-tête, raie invisible...",
      link: "/gallery-tissages",
    },

    {
      id: "3",
      subhead: "",
      mainhead: "Lissage",
      image:
        LissageImg,
      para: "Permet de réduire les boucles ou de lisser les cheveux. Défrisage, lissage brésilien, lissage japonais.",
      link: "/gallery-lissages",
    },

    {
      id: "4",
      subhead: "",
      mainhead: "Curly",
      image:
        CurlyImg,
      para: "Permet de réduire le volume et redessiner les boucles naturelles ou de reformer d'autres boucles.",
      link: "/gallery-curly",
    },

    {
      id: "5",
      subhead: "",
      mainhead: "Tresses hommes",
      image:
        TressesHommesImg,
      para: "Gagnez du temps le matin et facilitez vous la vie. Large choix de tresses, nattes...",
      link: "/gallery-tresses-hommes",
    },

    {
      id: "6",
      subhead: "",
      mainhead: "Tresses enfants",
      image:
        TressesEnfantsImg,
      para: "Gagnez du temps le matin et facilitez vous la vie. Large choix de tresses, nattes...",
      link: "/gallery-tresses-enfants",
    },
  ];

  return (
    document.title = pageTitle,
    <>
      <Header />
      <div className="prestations">
        <div className="prestations-breadcrumb">
          <BreadCrumbs />
        </div>
        <div className="container pt-5">
          <h2 className="text-center mt-4 " data-aos="fade-up">Prestations afro : tresses, tissage, lissage...</h2>
          <div
            className="row prestations-section justify-content-center"
            style={{ marginTop: "3rem" }}
          >
            {prestationsData.map((items) => (
              <div className="col-lg-6 col-md-12" key={items.id} data-aos="fade-up">
                <WhyChooseUs
                  image={items.image}
                  subhead={items.subhead}
                  mainhead={items.mainhead}
                  para={items.para}
                  link={items.link}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Prestations;
