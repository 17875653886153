import React from "react";
import Header from "../header/header";
import Footer from "../footer/footerComponent";
import MyGallery from "../gallery/gallery";

import tresses01 from "../../assets/galerie/tresses_femmes/tresses_afro_01.jpg";
import tresses02 from "../../assets/galerie/tresses_femmes/tresses_afro_02.jpg";
import tresses03 from "../../assets/galerie/tresses_femmes/tresses_afro_03.jpg";
import tresses04 from "../../assets/galerie/tresses_femmes/tresses_afro_04.jpg";
import tresses05 from "../../assets/galerie/tresses_femmes/tresses_afro_05.jpg";
import tresses06 from "../../assets/galerie/tresses_femmes/tresses_afro_06.jpg";
import tresses07 from "../../assets/galerie/tresses_femmes/tresses_afro_07.jpg";
import tresses08 from "../../assets/galerie/tresses_femmes/tresses_afro_08.jpg";
import tresses09 from "../../assets/galerie/tresses_femmes/tresses_afro_09.jpg";
import tresses10 from "../../assets/galerie/tresses_femmes/tresses_afro_10.jpg";
import tresses11 from "../../assets/galerie/tresses_femmes/tresses_afro_11.jpg";
import tresses12 from "../../assets/galerie/tresses_femmes/tresses_afro_12.jpg";
import tresses13 from "../../assets/galerie/tresses_femmes/tresses_afro_13.jpg";
import tresses14 from "../../assets/galerie/tresses_femmes/tresses_afro_14.jpg";
import tresses15 from "../../assets/galerie/tresses_femmes/tresses_afro_15.jpg";
import tresses16 from "../../assets/galerie/tresses_femmes/tresses_afro_16.jpg";
import tresses17 from "../../assets/galerie/tresses_femmes/tresses_afro_17.jpg";
import tresses18 from "../../assets/galerie/tresses_femmes/tresses_afro_18.jpg";
import tresses19 from "../../assets/galerie/tresses_femmes/tresses_afro_19.jpg";
import tresses20 from "../../assets/galerie/tresses_femmes/tresses_afro_20.jpg";
import tresses21 from "../../assets/galerie/tresses_femmes/tresses_afro_21.jpg";
import tresses22 from "../../assets/galerie/tresses_femmes/tresses_afro_22.jpg";
import tresses23 from "../../assets/galerie/tresses_femmes/tresses_afro_23.jpg";
import tresses24 from "../../assets/galerie/tresses_femmes/tresses_afro_24.jpg";
import tresses25 from "../../assets/galerie/tresses_femmes/tresses_afro_25.jpg";
import tresses26 from "../../assets/galerie/tresses_femmes/tresses_afro_26.jpg";
import tresses27 from "../../assets/galerie/tresses_femmes/tresses_afro_27.jpg";

const GalleryTresses = () => {
  const imagesTresses = [
      {
        original: tresses01,
        thumbnail: tresses01,
        description: "Tresses 1",
      },
      {
        original: tresses02,
        thumbnail: tresses02,
        description: "Tresses 2",
      },
      {
        original: tresses03,
        thumbnail: tresses03,
        description: "Tresses 3",
      },
      {
        original: tresses04,
        thumbnail: tresses04,
        description: "Tresses 4",
      },
      {
        original: tresses05,
        thumbnail: tresses05,
        description: "Tresses 5",
      },
      {
        original: tresses06,
        thumbnail: tresses06,
        description: "Tresses 6",
      },
      {
        original: tresses07,
        thumbnail: tresses07,
        description: "Tresses 7",
      },
      {
        original: tresses08,
        thumbnail: tresses08,
        description: "Tresses 8",
      },
      {
        original: tresses09,
        thumbnail: tresses09,
        description: "Tresses 9",
      },
      {
        original: tresses10,
        thumbnail: tresses10,
        description: "Tresses 10",
      },
      {
        original: tresses11,
        thumbnail: tresses11,
        description: "Tresses 11",
      },
      {
        original: tresses12,
        thumbnail: tresses12,
        description: "Tresses 12",
      },
      {
        original: tresses13,
        thumbnail: tresses13,
        description: "Tresses 13",
      },
      {
        original: tresses14,
        thumbnail: tresses14,
        description: "Tresses 14",
      },
      {
        original: tresses15,
        thumbnail: tresses15,
        description: "Tresses 15",
      },
      {
        original: tresses16,
        thumbnail: tresses16,
        description: "Tresses 16",
      },
      {
        original: tresses17,
        thumbnail: tresses17,
        description: "Tresses 17",
      },
      {
        original: tresses18,
        thumbnail: tresses18,
        description: "Tresses 18",
      },
      {
        original: tresses19,
        thumbnail: tresses19,
        description: "Tresses 19",
      },
      {
        original: tresses20,
        thumbnail: tresses20,
        description: "Tresses 20",
      },
      {
        original: tresses21,
        thumbnail: tresses21,
        description: "Tresses 21",
      },
      {
        original: tresses22,
        thumbnail: tresses22,
        description: "Tresses 22",
      },
      {
        original: tresses23,
        thumbnail: tresses23,
        description: "Tresses 23",
      },
      {
        original: tresses24,
        thumbnail: tresses24,
        description: "Tresses 24",
      },
      {
        original: tresses25,
        thumbnail: tresses25,
        description: "Tresses 25",
      },
      {
        original: tresses26,
        thumbnail: tresses26,
        description: "Tresses 26",
      },
      {
        original: tresses27,
        thumbnail: tresses27,
        description: "Tresses 27",
      },
    ];

  const pageTitle = "Coiffartiste. Galerie photo de tresses";

  return (
    document.title = pageTitle,
    <>
      <Header />
      <MyGallery images={imagesTresses} showBullets="true" showIndex="true" />
      <Footer />
    </>
  );
};
export default GalleryTresses;