import React from "react";
import Header from "../header/header";
import Footer from "../footer/footerComponent";
import MyGallery from "../gallery/gallery";

import coupe01 from "../../assets/galerie/coupes_femmes/coupe_01.jpg";
import coupe02 from "../../assets/galerie/coupes_femmes/coupe_02.jpg";
import coupe03 from "../../assets/galerie/coupes_femmes/coupe_03.jpg";

const GalleryCoupes = () => {
  const imagesCoupes = [
      {
        original: coupe01,
        thumbnail: coupe01,
        description: "Coupe 1",
      },
      {
        original: coupe02,
        thumbnail: coupe02,
        description: "Coupe 2",
      },
      {
        original: coupe03,
        thumbnail: coupe03,
        description: "Coupe 3",
      },
    ];

  const pageTitle = "Coiffartiste. Galerie photo de coupes femmes";

  return (
    document.title = pageTitle,
    <>
      <Header />
      <MyGallery images={imagesCoupes} showBullets="true" showIndex="true" />
      <Footer />
    </>
  );
};
export default GalleryCoupes;