import React from "react";
import LOGO from "../../assets/images/Logo.png";
import FB from "../../assets/images/fb.svg";
import "./footerComponent.css";
import { Link, useNavigate } from "react-router-dom";

export default function Footer({ head, para }) {
  const footerContactData = [
    {
      id: "1",
      number: "07 53 36 48 24",
      linkNumber: "callto:0753364824",
      mail: "coiffartiste@gmail.com",
      linkMail: "mailto:coiffartiste@gmail.com?subject=Demande%20de%20renseignements",
      location: "8 Passage du Trégor, 35000 RENNES",
    },
  ];

  const usefullLinks = [
    { id: "1", link: "Accueil", path: "/" },
    { id: "2", link: "Prestations", path: "/prestations" },
    { id: "3", link: "Formations", path: "/formations" },
    { id: "4", link: "Contact", path: "/contact" },
  ];
  const navigate = useNavigate();

  return (
    <>
      <div className="footer-section">
        <div className="container">
          {/* style={{marginRight:"13rem"}} */}
          <div className="row">
            <div className="col-lg-3 col-md-6 mb-5 mr-5">
              <div className="logo-name-sec" onClick={() => navigate("/")}>
                <img src={LOGO} alt="" />
                <span className="ralewayFont">Coiff'Artiste</span>
              </div>

              {footerContactData.map((items) => (
                <div className="unordered-list">
                  <ul>
                    <li className="number"><a href={items.linkNumber}>{items.number}</a></li>
                    <li>
                      <a href={items.linkMail} className="mail-add">
                        {items.mail}
                      </a>
                    </li>
                    <li className="per-loc">{items.location}</li>
                  </ul>
                </div>
              ))}
              <div className="social-logos">
                <a href="https://www.facebook.com/coiffartisteRennes/" target="_blank" rel="noreferrer"><img src={FB} alt="Logo Facebook" /></a>
              </div>
            </div>

            {/* Useful Links */}

            <div className="col-lg-2 col-md-6 mb-5 second-col">
              <h3>Liens rapides</h3>
              {usefullLinks.map((items) => (
                <div key={items.id} className="d-flex ">
                  <Link
                    to={items.path}
                    className="cursor-pointer"
                    style={{ textDecoration: "none" }}
                  >
                    <li>{items.link}</li>
                  </Link>
                </div>
              ))}
            </div>

          </div>
        </div>
      </div>
      <div className="section-end">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-12 col-md-12">
              <p className="text-center">
                © Copyright Pixel Pro Creations All Rights Reserved 2023
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
