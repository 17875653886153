import React from "react";
import Header from "../header/header";
import Footer from "../footer/footerComponent";
import MyGallery from "../gallery/gallery";

import chignon01 from "../../assets/galerie/chignons_afro_mariage/chignon_01.jpg";
import chignon02 from "../../assets/galerie/chignons_afro_mariage/chignon_02.jpg";
import chignon03 from "../../assets/galerie/chignons_afro_mariage/chignon_03.jpg";
import chignon04 from "../../assets/galerie/chignons_afro_mariage/chignon_04.jpg";
import chignon05 from "../../assets/galerie/chignons_afro_mariage/chignon_05.jpg";
import chignon06 from "../../assets/galerie/chignons_afro_mariage/chignon_06.jpg";
import chignon07 from "../../assets/galerie/chignons_afro_mariage/chignon_07.jpg";
import chignon08 from "../../assets/galerie/chignons_afro_mariage/chignon_08.jpg";
import chignon09 from "../../assets/galerie/chignons_afro_mariage/chignon_09.jpg";
import chignon10 from "../../assets/galerie/chignons_afro_mariage/chignon_10.jpg";
import chignon11 from "../../assets/galerie/chignons_afro_mariage/chignon_11.jpg";
import chignon12 from "../../assets/galerie/chignons_afro_mariage/chignon_12.jpg";
import chignon13 from "../../assets/galerie/chignons_afro_mariage/chignon_13.jpg";
import chignon14 from "../../assets/galerie/chignons_afro_mariage/chignon_14.jpg";
import chignon15 from "../../assets/galerie/chignons_afro_mariage/chignon_15.jpg";
import chignon16 from "../../assets/galerie/chignons_afro_mariage/chignon_16.jpg";
import chignon17 from "../../assets/galerie/chignons_afro_mariage/chignon_17.jpg";
import chignon18 from "../../assets/galerie/chignons_afro_mariage/chignon_18.jpg";
import chignon19 from "../../assets/galerie/chignons_afro_mariage/chignon_19.jpg";
import chignon20 from "../../assets/galerie/chignons_afro_mariage/chignon_20.jpg";
import chignon21 from "../../assets/galerie/chignons_afro_mariage/chignon_21.jpg";
import chignon22 from "../../assets/galerie/chignons_afro_mariage/chignon_22.jpg";
import chignon23 from "../../assets/galerie/chignons_afro_mariage/chignon_23.jpg";


const GalleryChignons = () => {
  const imagesChignons = [
      {
        original: chignon01,
        thumbnail: chignon01,
        description: "Chignon 1",
      },
      {
        original: chignon02,
        thumbnail: chignon02,
        description: "Chignon 2",
      },
      {
        original: chignon03,
        thumbnail: chignon03,
        description: "Chignon 3",
      },
      {
        original: chignon04,
        thumbnail: chignon04,
        description: "Chignon 4",
      },
      {
        original: chignon05,
        thumbnail: chignon05,
        description: "Chignon 5",
      },
      {
        original: chignon06,
        thumbnail: chignon06,
        description: "Chignon 6",
      },
      {
        original: chignon07,
        thumbnail: chignon07,
        description: "Chignon 7",
      },
      {
        original: chignon08,
        thumbnail: chignon08,
        description: "Chignon 8",
      },
      {
        original: chignon09,
        thumbnail: chignon09,
        description: "Chignon 9",
      },
      {
        original: chignon10,
        thumbnail: chignon10,
        description: "Chignon 10",
      },
      {
        original: chignon11,
        thumbnail: chignon11,
        description: "Chignon 11",
      },
      {
        original: chignon12,
        thumbnail: chignon12,
        description: "Chignon 12",
      },
      {
        original: chignon13,
        thumbnail: chignon13,
        description: "Chignon 13",
      },
      {
        original: chignon14,
        thumbnail: chignon14,
        description: "Chignon 14",
      },
      {
        original: chignon15,
        thumbnail: chignon15,
        description: "Chignon 15",
      },
      {
        original: chignon16,
        thumbnail: chignon16,
        description: "Chignon 16",
      },
      {
        original: chignon17,
        thumbnail: chignon17,
        description: "Chignon 17",
      },
      {
        original: chignon18,
        thumbnail: chignon18,
        description: "Chignon 18",
      },
      {
        original: chignon19,
        thumbnail: chignon19,
        description: "Chignon 19",
      },
      {
        original: chignon20,
        thumbnail: chignon20,
        description: "Chignon 20",
      },
      {
        original: chignon21,
        thumbnail: chignon21,
        description: "Chignon 21",
      },
      {
        original: chignon22,
        thumbnail: chignon22,
        description: "Chignon 22",
      },
      {
        original: chignon23,
        thumbnail: chignon23,
        description: "Chignon 23",
      },
    ];

  const pageTitle = "Coiffartiste. Galerie photo de chignons";

  return (
    document.title = pageTitle,
    <>
      <Header />
      <MyGallery images={imagesChignons} showBullets="true" showIndex="true" />
      <Footer />
    </>
  );
};
export default GalleryChignons;