import React from "react";
import Header from "../header/header";
import Footer from "../footer/footerComponent";
import "./Salon.css";
// import ReadMore from "./readmore/ReadMore";
import SalonImg1 from "../../assets/images/salon_coiffartiste_01.jpg";
import SalonImg2 from "../../assets/images/salon_coiffartiste_02.jpg";
import SalonImg3 from "../../assets/images/salon_coiffartiste_03.jpg";
import SalonImg4 from "../../assets/images/salon_coiffartiste_04.jpg";
import BreadCrumbs from "../breadCrumbs/BreadCrumbs";

const Salon = () => {
  const salon = [
    { id: "1", image: SalonImg1, para: "Espace attente et lavage" },
    { id: "2", image: SalonImg3, para: "Un large choix de produits" },
    { id: "3", image: SalonImg4, para: "Espace coiffure"},
  ];

  const coiffeuse = [
    { id: "1", image: SalonImg2, name: "Aicha", para: "15 ans d'expérience à votre service",},
  ];

  return (
    <>
      <Header />
      <div className="salon">
        <div className="salon-breadcrumb">
        <BreadCrumbs/>
        </div>
        <div className="container pt-5">
          <h1 className="text-center mt-4 " data-aos="fade-up">Un cadre chaleureux</h1>

          {/* Team section  */}

          <div className="salon-section">
            <div className="row">
              {salon.map((items) => (
                <div className="col-xs-12 col-lg-4 mb-2" key={items.id} data-aos="fade-up">
                  <div className="salon-image">
                    <img src={items.image} alt="" />
                    <div className="content">
                      <p className="text-center" style={{ width: "50%" }}>
                        {items.para}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
              <h2 className="text-center mt-4 mb-5" data-aos="fade-up">Votre coiffeuse</h2>
              {coiffeuse.map((items) => (
                <div className="col-lg-12 mb-2" key={items.id}>
                  <div className="salon-image" data-aos="fade-up">
                    <img src={items.image} alt="" />
                    <div className="content">
                      <h4 className="text-center" style={{ width: "80%" }}>
                        {items.name}
                      </h4>
                      <p className="text-center" style={{ width: "50%" }}>
                        {items.para}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Salon;
