import React from "react";
import Header from "../header/header";
import Footer from "../footer/footerComponent";
import MyGallery from "../gallery/gallery";

import tresses01 from "../../assets/galerie/tresses_hommes/tresses_afro_homme_01.jpg";
import tresses02 from "../../assets/galerie/tresses_hommes/tresses_afro_homme_02.jpg";
import tresses03 from "../../assets/galerie/tresses_hommes/tresses_afro_homme_03.jpg";
import tresses04 from "../../assets/galerie/tresses_hommes/tresses_afro_homme_04.jpg";
import tresses05 from "../../assets/galerie/tresses_hommes/tresses_afro_homme_05.jpg";
import tresses06 from "../../assets/galerie/tresses_hommes/tresses_afro_homme_06.jpg";
import tresses07 from "../../assets/galerie/tresses_hommes/tresses_afro_homme_07.jpg";
import tresses08 from "../../assets/galerie/tresses_hommes/tresses_afro_homme_08.jpg";
import tresses09 from "../../assets/galerie/tresses_hommes/tresses_afro_homme_09.jpg";
import tresses10 from "../../assets/galerie/tresses_hommes/tresses_afro_homme_10.jpg";
import tresses11 from "../../assets/galerie/tresses_hommes/tresses_afro_homme_11.jpg";
import tresses12 from "../../assets/galerie/tresses_hommes/tresses_afro_homme_12.jpg";
import tresses13 from "../../assets/galerie/tresses_hommes/tresses_afro_homme_13.jpg";
import tresses14 from "../../assets/galerie/tresses_hommes/tresses_afro_homme_14.jpg";
import tresses15 from "../../assets/galerie/tresses_hommes/tresses_afro_homme_15.jpg";
import tresses16 from "../../assets/galerie/tresses_hommes/tresses_afro_homme_16.jpg";
import tresses17 from "../../assets/galerie/tresses_hommes/tresses_afro_homme_17.jpg";
import tresses18 from "../../assets/galerie/tresses_hommes/tresses_afro_homme_18.jpg";
import tresses19 from "../../assets/galerie/tresses_hommes/tresses_afro_homme_19.jpg";
import tresses20 from "../../assets/galerie/tresses_hommes/tresses_afro_homme_20.jpg";
import tresses21 from "../../assets/galerie/tresses_hommes/tresses_afro_homme_21.jpg";
import tresses22 from "../../assets/galerie/tresses_hommes/tresses_afro_homme_22.jpg";
import tresses23 from "../../assets/galerie/tresses_hommes/tresses_afro_homme_23.jpg";
import tresses24 from "../../assets/galerie/tresses_hommes/tresses_afro_homme_24.jpg";
import tresses25 from "../../assets/galerie/tresses_hommes/tresses_afro_homme_25.jpg";

const GalleryTressesHommes = () => {
  const imagesTressesHommes = [
      {
        original: tresses01,
        thumbnail: tresses01,
        description: "Tresses hommes 1",
      },
      {
        original: tresses02,
        thumbnail: tresses02,
        description: "Tresses hommes 2",
      },
      {
        original: tresses03,
        thumbnail: tresses03,
        description: "Tresses hommes 3",
      },
      {
        original: tresses04,
        thumbnail: tresses04,
        description: "Tresses hommes 4",
      },
      {
        original: tresses05,
        thumbnail: tresses05,
        description: "Tresses hommes 5",
      },
      {
        original: tresses06,
        thumbnail: tresses06,
        description: "Tresses hommes 6",
      },
      {
        original: tresses07,
        thumbnail: tresses07,
        description: "Tresses hommes 7",
      },
      {
        original: tresses08,
        thumbnail: tresses08,
        description: "Tresses hommes 8",
      },
      {
        original: tresses09,
        thumbnail: tresses09,
        description: "Tresses hommes 9",
      },
      {
        original: tresses10,
        thumbnail: tresses10,
        description: "Tresses hommes 10",
      },
      {
        original: tresses11,
        thumbnail: tresses11,
        description: "Tresses hommes 11",
      },
      {
        original: tresses12,
        thumbnail: tresses12,
        description: "Tresses hommes 12",
      },
      {
        original: tresses13,
        thumbnail: tresses13,
        description: "Tresses hommes 13",
      },
      {
        original: tresses14,
        thumbnail: tresses14,
        description: "Tresses hommes 14",
      },
      {
        original: tresses15,
        thumbnail: tresses15,
        description: "Tresses hommes 15",
      },
      {
        original: tresses16,
        thumbnail: tresses16,
        description: "Tresses hommes 16",
      },
      {
        original: tresses17,
        thumbnail: tresses17,
        description: "Tresses hommes 17",
      },
      {
        original: tresses18,
        thumbnail: tresses18,
        description: "Tresses hommes 18",
      },
      {
        original: tresses19,
        thumbnail: tresses19,
        description: "Tresses hommes 19",
      },
      {
        original: tresses20,
        thumbnail: tresses20,
        description: "Tresses hommes 20",
      },
      {
        original: tresses21,
        thumbnail: tresses21,
        description: "Tresses hommes 21",
      },
      {
        original: tresses22,
        thumbnail: tresses22,
        description: "Tresses hommes 22",
      },
      {
        original: tresses23,
        thumbnail: tresses23,
        description: "Tresses hommes 23",
      },
      {
        original: tresses24,
        thumbnail: tresses24,
        description: "Tresses hommes 24",
      },
      {
        original: tresses25,
        thumbnail: tresses25,
        description: "Tresses hommes 25",
      },
    ];

  const pageTitle = "Coiffartiste. Galerie photo de tresses hommes";

  return (
    document.title = pageTitle,
    <>
      <Header />
      <MyGallery images={imagesTressesHommes} showBullets="true" showIndex="true" />
      <Footer />
    </>
  );
};
export default GalleryTressesHommes;